/**
 * Created by Hai on 10/26/16.
 */


(function ($) {
    $( "#reg-demo-button" ).on( "click", function() {
        var _name = $.trim($("#txtName").val());
        var _lastName = $.trim($("#txtLastName").val());
        var _company = $.trim($("#txtCompany").val());
        var _email = $.trim($("#txtEmail").val());
        var _mobile = $.trim($("#txtMobile").val());
        var _emp = parseInt($("#txtEmp").val());
        var _agent = $("#txtAgency").val() === "1" ? true : false;
        var _order = $.trim($("#txtOrder").val());

        var cond = _name.length < 1 || _lastName.length < 1 || _email.length < 1 || _company.length < 1 || _mobile.length < 1 || _emp === 0 || $("#txtAgency").val() === "2";
        if (cond) {
            alert("Bạn vui lòng nhập đầy đủ thông tin liên hệ, cảm ơn");
            return;
        }
        $("#demoregistration").modal('hide');
        ga('send', 'event', 'Submit Form', 'Click', 'Submit');
        $.ajax({
            url: 'https://mailgun.api.nguyen.agency/mail',
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify({
                signup_email: window.signup_email,
                name: _name,
                lastName: _lastName,
                company: _company,
                email: _email,
                mobile: _mobile,
                emp: parseInt(_emp),
                agt: _agent,
                order: _order,
            }),
            type: 'POST',
            success: function (data) {
                console.log(data);
                if (data > 0) {
                    //ga('send', 'event', 'Submit Form', 'Click', 'Submit');
                    $("#thankyou").modal();
                    //$("#ifFBTracking").attr("src", "/TrackingRegFB.html?t=" + (new Date() - new Date(1970, 1, 1)));

                } else {
                    if (data === -2) {
                        alert("Vui lòng nhập thông tin!");
                    }
                }
            }
        });
    });


})(jQuery);